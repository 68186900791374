import { isPlatformBrowser } from '@angular/common';
import { computed, effect, inject, PLATFORM_ID, Signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  patchState,
  Prettify,
  SignalStoreFeature,
  signalStoreFeature,
  SignalStoreFeatureResult,
  StateSignals,
  withComputed,
  withHooks,
  withMethods,
  withState,
  WritableStateSource,
} from '@ngrx/signals';
import { AppDimension } from 'common-ui';
import { BehaviorSubject, map, throttleTime } from 'rxjs';

export interface HideHeaderOnScrollSlice {
  isHeaderHidden: boolean;
}

const topSize = 100;
const throttleTimeMs = 100;
const minScrollDelta = 100;

export function withHideHeaderOnScroll<Input extends SignalStoreFeatureResult>(
  bpWidthFactory: (
    store: Prettify<StateSignals<Input['state']> & Input['props'] & Input['methods'] & WritableStateSource<Prettify<Input['state']>>>,
  ) => Signal<number>,
): SignalStoreFeature<
  Input,
  {
    state: HideHeaderOnScrollSlice;
    props: {
      hideOnScrollEnabled: Signal<boolean>;
      scrollIsAtTop: Signal<boolean>;
    };
    methods: {
      updateScrollPosition: (pos: number) => void;
      showHeader: () => void;
      hideHeader: () => void;
      toggleHeader: () => void;
    };
  }
> {
  const scrollPosition = new BehaviorSubject<number>(0);
  return signalStoreFeature(
    withState<HideHeaderOnScrollSlice>(() => ({
      isHeaderHidden: false,
    })),
    withComputed((store) => {
      const breakpointWidth = bpWidthFactory(store as any);
      const isBrowser = isPlatformBrowser(inject(PLATFORM_ID));
      const hideOnScrollEnabled = computed(() => {
        if (!isBrowser) return false;
        const appWidthBp = breakpointWidth();
        if (appWidthBp <= AppDimension.md) {
          return true;
        }
        return false;
      });

      const scrollIsAtTop = toSignal(scrollPosition.pipe(map((pos) => pos < topSize)), { initialValue: true });

      return {
        hideOnScrollEnabled,
        scrollIsAtTop,
      };
    }),
    withMethods((state) => {
      const updateScrollPosition = (pos: number) => {
        if (!state.hideOnScrollEnabled()) {
          pos = 0;
        }
        if (scrollPosition.value !== pos) {
          scrollPosition.next(pos);
        }
      };

      const showHeader = () => {
        patchState(state, { isHeaderHidden: false });
      };
      const hideHeader = () => {
        patchState(state, { isHeaderHidden: true });
      };
      const toggleHeader = () => {
        patchState(state, { isHeaderHidden: !state.isHeaderHidden() });
      };

      return {
        updateScrollPosition,
        showHeader,
        hideHeader,
        toggleHeader,
      };
    }),
    withHooks((state) => {
      let lastScrollPos = -1;
      const updateScrollHide = (scrollPos: number) => {
        if (lastScrollPos === -2) {
          lastScrollPos = scrollPos;
        }
        if (scrollPos < topSize) {
          patchState(state, { isHeaderHidden: false });
        } else {
          const delta = lastScrollPos - scrollPos;
          if (Math.abs(delta) >= minScrollDelta) {
            lastScrollPos = scrollPos;
            if (delta > 0) {
              patchState(state, { isHeaderHidden: false });
            } else {
              patchState(state, { isHeaderHidden: true });
            }
          }
        }
      };

      return {
        onInit: () => {
          scrollPosition
            .pipe(throttleTime(throttleTimeMs, undefined, { leading: true, trailing: true }), takeUntilDestroyed())
            .subscribe(updateScrollHide);

          effect(
            () => {
              state.hideOnScrollEnabled();
              patchState(state, { isHeaderHidden: false });
            },
            { allowSignalWrites: true },
          );
          effect(() => {
            state.isHeaderHidden();
            lastScrollPos = -2;
          });
        },
      };
    }),
  );
}
